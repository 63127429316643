<template>
  <div id="register-songs">
    <Navbar class="white-text dark-menu-icon" />

    <div class="background">
      <img src="@/assets/affiliate-background.svg" alt="background image" />
    </div>

    <section id="register-songs-main">
      <h1 class="page-title">Register Songs for Free & Get Paid</h1>
      <p>
        To get paid from some of the world's largest digital music services,
        your songs must be registered in the HFA database. The process is easy.
        Fill out the form and sign up for a free song registration account.
      </p>
      <img src="../assets/register-songs.png" alt="Register Your Songs" />
      <router-link
        :to="{ name: 'create-hfa-account', params: { type: 'apply' } }"
        class="button primary shadow"
        >REGISTER MY SONGS</router-link
      >
    </section>

    <Footer />
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "RegisterSongs",
  components: {
    Navbar,
    Footer
  },
  metaInfo: function() {
    return {
      title: " - Register Songs for Free and Get Paid",
      meta: [
        {
          name: "description",
          content:
            "To get paid from some of the world's largest digital music services, your songs must be registered in the HFA database"
        }
      ],
      link: [
        {
          vmid: "canonical",
          rel: "canonical",
          href: this.$store.getters["canonical"]()
        }
      ]
    };
  }
};
</script>

<style lang="scss">
#register-songs {
  > .background {
    display: none;

    @media (min-width: $desktop) {
      display: block;
      width: 100vw;
      overflow: hidden;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -2;
      text-align: right;
      height: calc(61px + 12vh + 400px + 40vh + 300px);

      img {
        width: 75vw;
      }
    }
  }
  section#register-songs-main {
    position: relative;
    margin-top: 20px;

    h1 {
      font-size: 1.25em;
      letter-spacing: 0.2px;
      margin-top: 0;
    }
    .button {
      font-size: 1.375em;
      margin: 20px auto;
      padding: 0.8em 0;
      width: 260px;
      display: block;
    }
    p {
      font-size: 1em;
      line-height: 1.89;
    }
    img {
      display: block;
      margin: 10px auto;
      width: 90vw;
    }

    @media (min-width: $desktop) {
      margin-top: 12.63021vh;

      h1 {
        font-size: 2.25em;
        margin-bottom: 3.001vw;
      }
      .button {
        font-size: 1.375em;
        margin: 7.687vw 0 0;
        padding: 0.8em 0;
        width: 260px;
      }
      p {
        font-size: 19px;
        width: 487px;
      }
      img {
        position: absolute;
        top: 14vh;
        right: 3.07467vw;
        width: 50vw;
      }
    }
  }

  #footer {
    margin-top: 20vh;

    @media (min-width: $desktop) {
      margin-top: 40vh;
    }
  }
}
</style>
